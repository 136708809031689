.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button.ui.button {
  font-family: "Messina";
  background-color: red;
  margin-bottom: 30px;
}

.header-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;

}

.paragraph {
  font-family: "Messina";
}

.result-image {
  margin-top: 20px;
  width: 350px;
  margin-bottom: 20px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono&display=swap');

.app-header{
  text-align: center;
  padding: 20px;
}


@font-face{
  font-family: "Messina";
  src: url("MessinaSans-Light.ttf") format("truetype");
}

@font-face{
  font-family: "Melion";
  src: url("Melion-Thin.otf") format("truetype");
}

@font-face{
  font-family: "Cellofy";
  src: url("Cellofy-Thin.otf") format("truetype");
}

.header{
  font-family: "Melion", monospace;
  font-size: 48px;
}


.input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.app-body{
  font-family: "Messina";
  padding: 20px;
  text-align: center;
}

.language-select{
  height: 40px !important;
  margin-bottom: 15px;
  outline: none !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ball {
  position: absolute;
  border-radius: 100%;
  opacity: 0.7;
}
